<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub">{{ item.band_sub }}</div>
        <div v-if="item.band_sub2" class="product-name-sub2">{{ item.band_sub2 }}</div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
        <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "8056262145548",
          brand: "Ray-Ban",
          price: "R2770.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB4425-Teru-8056262145548",
          text: "exclusive"
        },
        {
          imageOne: "8056262016848",
          brand: "Ray-Ban",
          price: "R2270.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3741-Emy-8056262016848",
          text: ""
        },
        {
          imageOne: "8056262017753",
          brand: "Ray-Ban",
          price: "R2270.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB4431-Xan-Bio-Based-8056262017753",
          text: ""
        },
        {
          imageOne: "8056262016794",
          brand: "Ray-Ban",
          price: "R2270.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB4425-Teru-Bio-Based-8056262016794",
          text: ""
        },
        {
          imageOne: "8056262142677",
          brand: "Ray-Ban",
          price: "R2270.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB3741-Emy-Bio-Based-8056262142677",
          text: ""
        },
        {
          imageOne: "8056262017722",
          brand: "Ray-Ban",
          price: "R2570.00",
          sale_price: "",
          url: "/sunglasshut-sa/Ray-Ban/RB4431-Xan-Bio-Based-8056262017722",
          text: ""
        }
      ],
    };
  },
};
</script>
