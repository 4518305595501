<template>
  <div class="product-listing">
    <div v-if="query">
      {{query}}
      <ais-instant-search  :search-client="searchClient" :index-name="database" :search-function="searchFunction">
        <ais-configure
          :hits-per-page.camel="4"
        />
        <ais-search-box />

        <div class="product-listing-sidebar">
          <div class="search-panel__filters">
            Brand:
            <ais-refinement-list attribute="Brand" />

          </div>
          <div class="search-panel__filters">
            Gender:
            <ais-refinement-list attribute="Gender" />
          </div>

        </div>
        <div class="product-listing-content">

          <ais-hits>
            <template v-slot:item="{ item }">
              <h2>{{ item.Name }}</h2>
            </template>
          </ais-hits>


        </div>
        <div class="pagination">
          <ais-pagination />
        </div>
      </ais-instant-search>

    </div>
    <Products v-show="false" />
  </div>
</template>

<script>
import "./ProductListing.scss";
import { useRoute } from "vue-router";
import list from "../../appData/productListing";
import Products from "../../components/organisms/ProductCard";

import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/satellite-min.css';

import { AisInstantSearch, AisHits, AisSearchBox, AisConfigure,AisPagination,AisRefinementList } from 'vue-instantsearch/vue3/es'

export default {
  props: ['brands','genders'],
  name: "ProductListing",
  components: { Products,AisInstantSearch,AisSearchBox,AisHits,AisConfigure,AisPagination,AisRefinementList },
  data() {
    return {
      query: this.$route.query.brand,
      data: list.list,
      searchClient: algoliasearch(
          this.apiId,
          this.apiKey
      ),

    };
  },
  methods: {
    // a computed getter
    searchFunction(helper) {
      const page = helper.getPage(); // Retrieve the current page
      console.log(this.query);
      helper.setQuery(this.query) // this call resets the page
      .setPage(page) // we re-apply the previous page
      .search();
    },
  },
  created() {
    // props are exposed on `this`

  },
  mounted() {
    const route = useRoute();
    console.log(route.params.id, "route");
    this.query = route.params.brand;
    console.log(this.query);


  },
};
</script>
