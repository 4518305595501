<template>
  <div className="search-component">{{src_link}}
    <div id="autocomplete" />
  </div>
</template>
<script>
import "./AlgoliaAutocomplete.scss";
import { h, Fragment, render, onMounted } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';

import '@algolia/autocomplete-theme-classic';

export default {
  name: "AlgoliaAutocomplete",

  setup(){

    let searchClient = algoliasearch('ZIETC15FK5','f368650b8a7822fad1bda1f8be2ada53')

    onMounted(() => {
      autocomplete({
        initialState: {
          // This uses the `search` query parameter as the initial query
          query: new URL(window.location).searchParams.get(''),
        },
        container: '#autocomplete',
        openOnFocus: true,
        getSources({ query }){
          return [
            {
              sourceId: 'Catalog',
              getItems(){
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: process.env.VUE_APP_DATABASE,
                      query,
                      params: {
                        hitsPerPage: 10,
                        attributesToSnippet: ['name:20', 'brand:10'],
                        snippetEllipsisText: '...',
                      }
                    }
                  ]
                })
              },
              templates: {
                item({ item, components }) {

                  var src_link = '/sunglasshut-sa/'+item.brand+'/'+item.name.replace(/\s/g , "-")+'-'+item.ean;
                  var product_image = `https://za.sunglasshut.com/shared_assets/product_images/${item.ean}_000A.jpg`;
                  return (
                      <div className="aa-ItemWrapper">
                        <div className="aa-ItemContent">
                          <div className="aa-ItemContentBody">
                            <a href={src_link}>
                            <img src={product_image}/>
                            <div className="aa-ItemContentTitle">
                              <components.Snippet hit={item} attribute="brand"/> - <components.Snippet hit={item} attribute="name" /><br />
                              <components.Snippet hit={item} attribute="color" /> -   <components.Snippet hit={item} attribute="short_desc" />
                              <div>
                              </div>
                            </div>
                          </a>
                          </div>
                        </div>
                      </div>
                  );
                },
              },


            }
          ]
        },
        renderer: {
          createElement: h,
          Fragment,
        },
        render({ children }, root){
          render(children, root)
        }
        ,
      })
    })
    return {
    }
  }

};
</script>
